<i18n>
{
  "de": {
    "pageTitle": "Portfolioeinstellungen",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
    </template>

    <template #default>
      <ButtonWrapper>
        <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }">{{
          $t('returnButton')
        }}</router-link>
      </ButtonWrapper>
      <br /><br />
      <EditPortfolioParams :portfolio="portfolio" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import EditPortfolioParams from '@/components/admin-portfolios/EditPortfolioParams.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'adminPortfolioSettings',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    EditPortfolioParams,
    ButtonWrapper,
    PageTitle,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
